@font-face {
  font-family: "Jaapokki enchance";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Jaapokkienchance-Regular.otf");
}
@font-face {
  font-family: "Jaapokki subtract";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Jaapokkisubtract-Regular.otf");
}
@font-face {
  font-family: "Jaapokki";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Jaapokki-font-package-1.0/Regular/web/jaapokki-regular.woff") format('woff'),
        url("../../fonts/Jaapokki-font-package-1.0/Regular/web/jaapokki-regular.ttf") format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Poppins-Regular.ttf") format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Poppins-Medium.ttf") format('truetype');
}
body{
  height: 100%;
  width: 100%;
  padding-bottom: -12000px;
}


.loading-screen{
  background-color: #002A6A;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  color: white;

  h1{
    text-transform: uppercase;
    font-size: 5vw;
    margin: 50vh auto;
    width: fit-content;
    transform: translateY(-50%);
    color: #ffffff !important;
  }
}

.backgroundImageDiv {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  //z-index: 999;
  //top: -153vw;
  //right: -31vw;
  //transform: scale(1.05);

}
.backgroundImage {
  position: relative;
  width: 103vw;
  visibility: hidden;
}

#pulldown {
  position: absolute;
  height: 12vw;
  top: 42vw;
  right: 17vw;
  display: none;
}
#pulldown-text {
  position: absolute;
  height: 12vw;
  top: 53vw;
  right: 13vw;
  color: rgba(131, 129, 132, 0.78);
  font-size: 0.8rem;
  text-align: center;
  display: none;
}

#solutions {
  position: relative;
  top: 45vw;
  z-index: 3;

}
#solution-title {
  position: relative;
  margin-top: -20vw;
  left: 8vw;
  font-family: Poppins, sans-serif;


  span {
    font-weight: 600;
    font-size: 15vw;
    color: white;
  }
}
#solution-container {
  background-color: #0b133d;
  margin-top: -15vw;
  padding-top: 40vw;
}
#solution-fig {
  display: block;
  width: 85%;
  margin: 0 auto 7vw auto;

}
.solution-paragraph {
  font-family: Poppins, sans-serif;
  color: white;
  padding: 13vw;

  .title {
    font-size: 7vw;
    font-weight: 600;
    margin-bottom: 5vw;
  }

  .title::selection {
    background-color: white;
  }

  .content {
    font-size: 4vw;
    text-align: justify;
    font-weight: 400;

  }

  .content::selection {
    background-color: white;
  }

}
.solution-icon {


  img {
    width: 20%;
    display: block;
    margin: 5vw auto 5vw auto;
  }
}

@media screen and (max-width:614px) {


  #navbar1 {
    position: relative;
    top: -480vw;
    right: -31vw;
    z-index: 90;
    transform: scale(1.05);
    background-image: url("../../img/bg/Artboard 9.svg");

    img:first-of-type {
      z-index: 90;
    }

    #menu {
      position: absolute;
      top: 93vw;
      right: 37vw;
      font-size: 4.3vw;
      text-align: right;
      color: white;


      .item {
        margin-bottom: 3vw;
        font-family: Poppins, sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        color: white;

      }

      .item.big {
        font-size: 6vw;
        margin-top: -2.5vw;
        margin-right: 0.9vw;
      }

      a {
        text-decoration: none;
        color: white;

      }

      a:visited {
        text-decoration: none;
        color: #ffffff;
      }

      .nav-icon {
        height: 7vw;
        display: block;
        float: right;
        clear: right;

      }

      .nav-icon.gap {
        margin-top: 9vw;
      }


    }

  }



  #bg-shape1 {
    position: absolute;
    top: -25vw;
    left: -10vw;
  }
  #bg-shape2{display: none}
  #bg-shape3 {
    position: absolute;
    top: 65vw;
    left: -15vw;
  }
  #bg-shape4 {
    position: absolute;
    top: 80vw;
    right: -25vw;
    transform: scale(1.4);
  }
  #bg-shape5 {
    position: absolute;
    top: 105vw;
    transform: scale(1.5);
    right: 20vw;
  }
  #bg-shape6 {
    position: absolute;
    top: 185vw;
    transform: scale(1.6);
  }
  #bg-fig {
    position: absolute;
    top: 60vw;
    right: -11vw;
    transform: scale(0.8);
  }


  #logo {

    img {
      position: absolute;
      width: 23vw;
      right: 7vw;
      top: 7vw;
      z-index: 99;
    }
  }
  #top-text {
    font-family: "Jaapokki", sans-serif;
    font-size: 12vw;

    span {
      position: absolute;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
    }

    span:first-child {
      top: 30vw;
      left: 8vw;
    }

    span:nth-child(2) {
      top: 45vw;
      left: 25vw;

    }
  }
  #short-text {


    span {
      position: absolute;
      color: #ffffff;
      font-size: 4.5vw;
      text-transform: uppercase;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
    }

    span:first-child {
      top: 75vw;
      left: 5vw;
    }

    span:nth-child(2) {
      top: 85vw;
      left: 12.5vw;
    }

    span:nth-child(3) {
      top: 93vw;
      left: 20vw;
    }

    span:nth-child(4) {
      top: 101vw;
      left: 33vw;
      font-size: 6vw;
    }
  }
  #name {
    font-family: "Jaapokki", sans-serif;
    font-size: 25vw;
    color: #ffffff;

    span {
      position: absolute;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
    }

    span:first-child {
      top: 150vw;
      left: 10vw;
    }

    span:nth-child(2) {
      top: 173vw;
      left: 40vw;

    }
  }
  #bg {
    width: 100%;
    height: 250vw;
    position: relative;
    margin-bottom: 75vw;
  }

  #testdiv {
    position: absolute;
    top: -153vw;
    right: -31vw;
    transform: scale(1.05);
    background-color: #4F5D75;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .react-draggable, .cursor {
    cursor: grab;
  }
  .no-cursor {
    cursor: auto;
  }
  .cursor-y {
    cursor: ns-resize;
  }
  .cursor-x {
    cursor: ew-resize;
  }



  #blurBg {
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(68, 68, 68, 0.75);
    position: fixed;
    top: 0;
    opacity: 0;
    transition: opacity 1s ease;
  }


  #solutionTop1 {
    position: absolute;
    //top: 100vw;
    left: -25vw;
  }
  #solutionTop2 {
    position: absolute;
    transform: scale(1.2);
    top: 11vw;
    right: -25vw;
  }
  #solutionTop3 {
    position: absolute;
    transform: scale(1.2);
    top: 35vw;
  }
  #solutionTop {
    position: relative;
    height: 70vw;
    margin-bottom: -60vw;
    //margin-top: -250vw;

  }



  #solutionBot1 {
    position: relative;
    transform: scale(1.3);
    top: -195vw;
    left: 11vw;
    z-index: 1;
  }
  #solutionBot2 {
    position: relative;
    top: -115vw;
    left: -12vw;
    transform: scale(1.1);
  }
  #solutionBot3 {
    position: relative;
    transform: scale(0.85);
    top: -20vw;
    right: -30vw;
  }
  #solutionBot4 {
    position: relative;
    transform: scale(1.6);
    top: 65vw;
  }

  #about {
    margin-top: -90vw;
    font-family: Poppins, sans-serif;
    color: #0A243A;
    padding: 5vw 13vw 13vw;

    #about-title {
      position: relative;
      left: -5vw;
      font-family: Poppins, sans-serif;

      span {
        font-weight: 600;
        font-size: 15vw;
      }
    }

    #about-fig {
      display: block;
      width: 100%;
      margin: 15vw auto 10vw auto;
    }

    .about-paragraph {
      font-family: Poppins, sans-serif;

      .title {
        font-size: 7vw;
        font-weight: 600;
        margin-bottom: 5vw;
      }

      .title::selection {
        background-color: black;
      }

      .content {
        font-size: 4vw;
        text-align: justify;
        font-weight: 600;
        padding-bottom: 13vw;
      }

      .content::selection {
        background-color: black;
      }



    }

    .about-icon {


      img {
        width: 20%;
        display: block;
        margin: 5vw auto 5vw auto;
      }
    }
  }

  #contact {
    padding-top: 90vw;
    font-family: Poppins, sans-serif;


    #contact1 {
      position: relative;
      transform: scale(1.5);
      left: 20vw;
      top: 10vw;
    }

    #contact2 {
      position: relative;
      transform: scale(1.2);
      right: 20vw;
      top: -3vw;
    }

    #contact3 {
      position: relative;
      transform: scale(1.2);
      left: 37vw;
      top: -40vw;
    }

    #contact4 {
      position: relative;
      transform: scale(1.3);
      right: 4vw;
      top: -56vw;
    }

    #contact-title {
      position: relative;
      margin-top: -70vw;
      left: 8vw;
      font-family: Poppins, sans-serif;


      span {
        font-weight: 600;
        font-size: 15vw;
        color: #ffffff;
      }
    }

    #contact-container {
      background-color: #0A1B44;
      margin-top: -10vw;
      padding-top: 20vw;
      min-height: 500px;
      padding-bottom: 5vw;
    }

    #contactFig {
      position: relative;
      width: 80%;
      margin: -220vw auto 80vw auto;
      display: block;

    }

    #contact-form {
      position: relative;
      margin: 0 10% 0 10%;

      input, textarea {
        display: block;
        font-family: Poppins, sans-serif;
        font-size: 3.3vw;
        border-radius: 5px;
        padding: 10px 20px;
        border: none;
        color: black;
        width: 100%;
        margin-top: 2vw;
        margin-bottom: 3vw;
      }

      input::selection, textarea::selection {
        background-color: black;
      }

      input:focus, textarea:focus {
        //box-shadow: 0.4rem 0.4rem 1rem rgba(0, 0, 0, 0.44);
        background-color: #4F5D75;
        outline: none;
        color: white;

      }

      input:focus::placeholder, textarea:focus::placeholder {
        color: #d1d1d1;
      }

      input[type=submit] {
        margin-top: 7vw;
        background-color: white;
      }

      input[type=submit]:focus {
        color: black;
      }

      input[type=submit]:active {
        background-color: #4F5D75;
        color: white;
      }

      label {
        font-family: Poppins, sans-serif;
        color: white;
        font-size: 3.4vw;
        margin-left: 1vw;
      }
    }


  }

  #scrollToTopBtn{
    position: fixed;
    z-index: 200;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 50%;
    padding: 2vw;
    box-shadow: #000000a6 3px 3px 5px 2px;

    img{
      width: 7vw;
    }

  }


}

.popup-bg{
  position: fixed;
  z-index: 199;
  background-color: rgba(0, 0, 0, 0.34);
  width: 100vw;
  height: 100vh;
  padding: 0 15% 0 15%;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.popup{



  font-family: Poppins,sans-serif;
  background-color: white;
  position: fixed;
  z-index: 200;
  display: block;
  top: 35%;
  padding: 20px;
  border-radius: 5px;
  width: 70%;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;


  h1{
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    background-color: #002A6A;
    color: white;
  }

  p{
    font-size: 19px;
    text-align: justify;
  }

  .popup-sign{
    width: 50px;
    float: right;
    margin: 10px;
  }
}



#opt-warning{
  display: none;
  background-color: rgba(29, 29, 29, 0.97);
  color: white;
  font-size: 3rem;
  text-align: center;
  font-family: Poppins,sans-serif;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */


  #warning{
    position: relative;
    top: 8rem;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  div+p{
    color: rgba(131, 129, 132, 0.78);
    font-size: 1.2rem;
    position: absolute;
    bottom: 1%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 0);
  }
}

@media screen and (min-width:615px) {
  /*#opt-warning{
    display: block;
  }*/

  .backgroundImage{
    height: 130vh;

  }
  #navbar1{
    position: fixed;
    height: 100vw;
    top: -10vw;
    left: -55vw;
    z-index: 80;
    background-image: url("../../img/bg/Artboard 9.svg");

    img:first-of-type {
      z-index: 99;
    }

    #menu {
      opacity: 1 !important;
      z-index: 100;
      position: absolute;
      top: 30vw;
      left: 58.5vw;
      font-size: 1.7vw;
      text-align: left;
      color: white;


      .item {
        margin-bottom: 1.5vw;
        font-family: Poppins, sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        cursor: pointer;

      }

      .item.big {
        font-size: 2vw;
        margin-top: -1.2vw;
        margin-right: 1.1vw;
      }

      a {
        text-decoration: none;
        color: white;

      }

      a:visited {
        text-decoration: none;
        color: #ffffff;
      }

      .nav-icon {
        height: 2vw;
        display: block;
        float: left;
        clear: left;
        position: relative;
        bottom: 0;

      }

      .nav-icon.gap {
        margin-top: 5vw;
      }


    }
  }
  #logo {

    img {
      position: fixed;
      width: 10vw;
      left: 3vw;
      top: 3vw;
      z-index: 99;
    }
  }

  #bg{
    position: relative;


    #bg-shape1 {
      z-index: 72;
      position: absolute;
      width: 75vw;
      top: -10vw;
      left: -19vw;
    }
    #bg-shape2 {
      z-index: 67;
      position: absolute;
      width: 75vw;
      top: 35vw;
      left: 3vw;
    }
    #bg-shape6 {
      z-index: 68;
      position: absolute;
      top: 15vw;
      left: -5vw;
      width: 70vw;
    }
    #bg-shape5 {
      z-index: 69;
      position: absolute;
      width: 55vw;
      top: 19vw;
      right: 1vw;
    }
    #bg-shape4 {
      width: 67vw;
      position: absolute;
      top: 15vw;
      right: -15vw;
      z-index: 70;
    }
    #bg-shape3 {
      z-index: 71;
      width: 35vw;
      position: absolute;
      top: 0.2vw;
      right: 20vw;
    }
    #bg-fig {
      position: absolute;
      z-index: 80;
      width: 50vw;
      top: 6vw;
      right: 0;
    }
  }


  #short-text {


    span {
      z-index: 99;
      position: absolute;
      color: #ffffff;
      font-size: 2vw;
      text-transform: uppercase;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
    }

    span:first-child {
      top: 4vw;
      left: 53vw;
    }

    span:nth-child(2) {
      top: 9vw;
      left: 57vw;
    }

    span:nth-child(3) {
      top: 14vw;
      left: 61vw;
    }

    span:nth-child(4) {
      top: 18vw;
      left: 67vw;
      font-size: 2vw;
    }
  }
  #name {
    z-index: 99;
    position: absolute;
    font-family: "Jaapokki", sans-serif;
    font-size: 8vw;

    color: #ffffff;

    span {
      position: absolute;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
    }

    span:first-child {
      top: 18vw;
      left: 27vw;

    }

    span:nth-child(2) {
      top: 27vw;
      left: 35vw;

    }
  }
  #top-text {
    display: none;
  }

  #solutionTop {
    position: relative;
    top: 90vw;
    width: 100%;
    margin-top: -85vw;

    #solutionTop1 {
      position: absolute;
      //top: 100vw;
      left: -25vw;
    }
    #solutionTop2 {
      position: absolute;
      transform: scale(1.2);
      top: 11vw;
      right: -25vw;
    }
    #solutionTop3 {
      position: absolute;
      transform: scale(1.2);
      top: 20vw;
    }
  }

  #solutions {
    top: 150vw;
  }
  #solution-title {
    margin-top: -10vw;
    left: 23vw;


    span {
      font-size: 10vw;
      position: absolute;
      top: -13vw;
    }
  }
  #solution-container {
    margin-top: -15vw;
    padding-top: 10vw;
    padding-left: 20vw;

  }
  #solution-fig {
    width: 45%;
    margin: 0 auto 3vw auto;
    top: -3vw;
    position: relative;
  }
  .solution-paragraph {
    font-family: Poppins, sans-serif;
    color: white;
    padding: 3vw;
    margin: 0 5vw 0 5vw;



    .title {
      font-size: 3vw;
      font-weight: 600;
      margin-bottom: 3vw;
    }



    .content {
      font-size: 2vw;
      text-align: justify;
      font-weight: 400;
      margin: 0;

    }

    .content::selection {
      background-color: white;
    }

  }
  .solution-icon {
    img {
      width: 25%;
      display: block;
      //margin: 5vw auto 5vw auto;
    }
  }

  #solutionBot1 {
    position: relative;
    transform: scale(1.3);
    top: -100vw;
    left: 20vw;
    z-index: 1;
  }
  #solutionBot2 {
    position: relative;
    top: -15vw;
    left: -25vw;
    transform: scale(0.9);
  }
  #solutionBot3 {
    position: relative;
    transform: scale(0.7);
    top: 55vw;
    right: -30vw;
  }
  #solutionBot4 {
    position: relative;
    transform: scale(1.6);
    top: 140vw;
  }


  #about {
    margin-top: -50vw;
    font-family: Poppins, sans-serif;
    color: #0A243A;
    padding: 13vw 13vw 13vw 20vw;

    #about-title {
      position: relative;
      left: 5vw;
      font-family: Poppins, sans-serif;

      span {
        font-weight: 600;
        font-size: 10vw;
    }
    }

    #about-fig {
      display: block;
      width: 100%;
      margin: 10vw auto 5vw auto;
      padding-left: 10vw;
    }

    .about-paragraph {
      font-family: Poppins, sans-serif;

      .title {
        font-size: 3vw;
        font-weight: 600;
        margin-bottom: 2vw;
        padding-left: 5vw;
      }

      .title::selection {
        background-color: black;
      }

      .content {
        font-size: 2vw;
        text-align: justify;
        font-weight: 600;
        padding-bottom: 10vw;
        padding-left: 5vw;
      }

      .content::selection {
        background-color: black;
      }

    }

    .about-icon {


      img {
        width: 25%;
        display: block;
        padding-left: 5vw;
        margin: 0 auto 10vw auto;
      }
    }
  }
  #contact {
    padding-top: 90vw;
    font-family: Poppins, sans-serif;


    #contact1 {
      position: relative;
      transform: scale(1.5);
      left: 20vw;
      top: -50vw;
    }

    #contact2 {
      position: relative;
      transform: scale(1.2);
      right: 20vw;
      top: -63vw;
    }

    #contact3 {
      position: relative;
      transform: scale(1.2);
      left: 37vw;
      top: -100vw;
    }

    #contact4 {
      position: relative;
      transform: scale(1.3);
      right: 4vw;
      top: -116vw;
    }

    #contact-title {
      position: relative;
      margin-top: -70vw;
      margin-left: 20vw;
      left: 8vw;
      font-family: Poppins, sans-serif;


      span {
        font-weight: 600;
        font-size: 10vw;
        color: #ffffff;
      }
    }

    #contact-container {
      background-color: #0A1B44;
      margin-top: -10vw;
      padding-top: 15vw;
      padding-left: 20vw;
      min-height: 500px;
      padding-bottom: 5vw;
    }

    #contactFig {
      position: relative;
      width: 80%;
      margin: -250vw auto 80vw auto;
      display: block;
      padding-left: 20vw;

    }

    #contact-form {
      position: relative;
      margin: 0 10% 0 10%;
      z-index: 111;

      textarea{
        height: 15vw;
      }

      input, textarea {
        display: block;
        font-family: Poppins, sans-serif;
        font-size: 1.3vw;
        border-radius: 5px;
        padding: 10px 20px;
        border: none;
        color: black;
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 1.5vw;
      }

      input::selection, textarea::selection {
        background-color: black;
      }

      input:focus, textarea:focus {
        //box-shadow: 0.4rem 0.4rem 1rem rgba(0, 0, 0, 0.44);
        background-color: #4F5D75;
        outline: none;
        color: white;

      }

      input:focus::placeholder, textarea:focus::placeholder {
        color: #d1d1d1;
      }

      input[type=submit] {
        margin-top: 7vw;
        background-color: white;
      }

      input[type=submit]:focus {
        color: black;
      }

      input[type=submit]:active {
        background-color: #4F5D75;
        color: white;
      }

      label {
        font-family: Poppins, sans-serif;
        color: white;
        font-size: 2vw;
      }
    }


  }



  #scrollToTopBtn{
    display: none;
  }

}

