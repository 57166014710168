// MAIN DIV on Page1.js
.histDisplay{
  position: relative;
  margin-bottom: 0;
  width: 100vw;

  // on works.js
  .main-title {
    position: relative;
    margin-top: -20vw;
    left: 8vw;
    font-family: Poppins, sans-serif;


    span {
      font-weight: 600;
      font-size: 15vw;
      color: #173c57;
    }
  }
  .fig{
    img{
      width: 60%;
      display: block;
      margin-top: 8vw ;
      margin-bottom: 20vw;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .paragraph {
    a {
      text-decoration: none;
    }

    font-family: Poppins, sans-serif;
    color: #173c57;
    padding: 3vw;
    margin: 20vw 5vw;

    .title {
      font-size: 5vw;
      font-weight: 600;
      margin-bottom: 4vw;
      text-align: center;
    }

    .image img {
      width: 80%;
      display: block;
      margin: 0 auto;
      border-radius: 6px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    .info-card {

      overflow: hidden;
      transition: max-height 1s;


      .content {
        margin: 5vw auto;
        width: 80%;
        font-size: 4vw;
        text-align: justify;
        font-weight: 600;
      }

      .content::selection {
        background-color: white;
      }


      .publish{
        text-align: right;
        font-size: max(1.2vw, 10px);
        bottom: 2vw;
        position: relative;
        margin-right: 7vw;
      }

      .tech {
        width: 80%;
        column-gap: 0;
        column-count: 2;


        img {
          height: 13vw;
          width: 100%;
          margin: 0 auto 1.5rem;
          display: block;
        }
      }

      .visit-btn:hover {
        background-color: white;
        color: #173c57;

      }

      .visit-btn {
        z-index: 99;
        border: #173c57 solid 3px;
        text-decoration: none;
        display: block;
        margin: 10vw auto;
        border-radius: 5px;
        padding: 10px 20px;
        width: 80%;
        text-align: center;
        background-color: #173c57;
        color: white;
        font-size: 6vw;


      }

    }

  }
}


#comps {
  margin-top: max(35vw, 150px);
  .main-title span{
    font-size: 10vw !important;
  }

  .image {
    margin: 0 auto;
    display: block;
  }

  .slick-dots{
    display: none !important;
  }

  .title{
    margin-bottom: 0;
  }
  .challenge{
    text-align: center;
    font-size: max(1.1vw, 12px);
  }
  .projectName{
    margin-top: 1.2vw;
    margin-bottom: 2vw;
    text-align: center;
    font-weight: bold;
    font-size: 2.2vw;
  }
  .quote{
    font-size: max(0.9vw, 16px);
    text-align: justify;
    text-align-last: left;
    margin: 0 5vw 2.5vw  5vw ;
  }
}





// ---- MOBILE VIEW ----
@media screen and (max-width:614px) {

  .histDisplay {


    margin-top: -260vw;
    margin-bottom: 50vw;

    .tech{
      margin: 10vw auto;

    }

  }
}


// ---- DESKTOP VIEW ----
@media screen and (min-width:615px) {
  .histDisplay {


    margin-top: 100vw;
    padding-left: 20vw;

    .paragraph:last-of-type{
      margin-bottom: 10vw;
    }

    .paragraph{
      max-width: 1200px;
      margin: 10vw auto 0;

      .image{
        max-width: 600px;
        margin: 0 auto;
        cursor: pointer;
      }

      .content{
        font-size: 1.3rem !important;
      }

      .tech{
        margin: 2vw auto;
        width: 40% !important;
        img{
          height: 3vw !important;
        }
      }

      .visit-btn{
        max-width: 400px;
        font-size: 3rem !important;
        margin: 3vw auto !important;
      }
    }

  }
}
