.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Gotham-Light.otf");
}

html{
  width: 100%;
  height: 100%;


  --1-color: #DA493E;
  --2-color: #2D3142;
  --3-color: #1F3599;
  --4-color: #4F5D75;
  --5-color: #BFC0C0;

  --bg-color-description: #0039B5;
  --bg-color-about: #0034A5;
  --bg-color-skills: #002F95;
  --bg-color-contact: #002A84;

  --bg-color-description: #ffffff;
  --bg-color-about: #ffffff;
  --bg-color-skills: #ffffff;
  --bg-color-contact: #ffffff;

  --main-text-color: #212529;
  --main-text-color: #fff;

}

body{
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  font-size: 10px;
}
::-webkit-scrollbar{
  display: none;
}
::selection{
  color: #fff;
  background-color: transparent;
}



.Tilt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background-color: #7e56ff;
  //background-image: linear-gradient(150deg, #607D8B 0%, #9C27B0 100%, #ff1ff7 100%);
  background-size: cover;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  transform-style: preserve-3d;
  position: absolute;
  top: 0;

}

.Tilt-inner {
  z-index: 99;

  transform: translateZ(100px);
  font-size: 100px;

}

#text{
  font-size: 100px;

  position: absolute;
  top: 400px;
}


#text-top{
  font-size: 100px;
  position: absolute;
  top: 400px;
  color: black;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
}


#text-other {
  font-size: 100px;
  display: flex;
}

.home-container{
  position: relative;
  border-radius: 0 0 0 50px;
  overflow: hidden;
  padding: 70px 25px 10px 25px;
  line-height: 1.45;
  font-family: "Jaapokki", sans-serif;
  margin-top: -60px;
  box-sizing: content-box;
  box-shadow: -2px 2px 5px 0 #2347ab14;


  background-color: white;

}


.container-title{
  margin: 15px 15px 7px 15px;
  display: flex;
  font-size: 23px;

}

.container-title-long{
  margin-top: 20px;
}

.container-title-long .container {
}

.container-title-long .col-3{
  padding: 0;
}

.container-icon{
  width: 27px;
  height: 27px;
  margin: 0 10px 3px 5px;
  filter: invert(0);
  display: inline-block;
}

.container-icon svg{
  width: 27px;
  height: 27px;
  margin-bottom: 7px;
}

.container-icon path{
  transition: fill 0.2s ease-in-out;
}




.container-content{
  text-align: justify;
  margin: 5px 0 0 5px;
  font-family: "Ubuntu Light", sans-serif;
  color: #ffffff;
  padding-bottom: 25px;
}

#h-image-container{
  background-color: transparent;
  z-index: 9;
  height: 350px;
  width: 100%;
  padding: 10px 0 0 0;
  margin-top: 30px;
  font-family: "Jaapokki", sans-serif;
}

.home-image{
  width: 100%;
  height: 100%;
  /*background-image: url("img/home-image.jpg");*/
  filter: blur(1px);
  transform: scale(1.35);
  background-position: center right -10%;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}


#h-description{
  z-index: 8;
  background-color: var(--bg-color-description);

}

#h-about{
  z-index: 7;
  background-color: var(--bg-color-about);

}

#h-skills{
  z-index: 5;
  background-color: var(--bg-color-skills);

}

#h-skills .container-title-long svg{
  fill: #ff1ff7;
}

#h-contact{
  z-index: 4;
  background-color: var(--bg-color-contact);

}
