#img-cont-content{
    z-index: 15;
    top: 55px;
    position: absolute;
    padding: 0 0 0 45px;
    line-height: 1;
    margin: 0;
    color: #ffffff;
    height: 100%;
    text-shadow: 1px 1px 1px #00000030;
}


#img-cont-content h1{
    color: #fff;
}

#img-cont-content div:first-child{
    margin-top: 30px;
    width: 100%;
}

#img-cont-content div:first-child h1{
    margin: 0;
    font-size: 42px;
    line-height: 1;
}

#img-cont-content div:nth-child(2){
    margin-bottom: 25%;
    position: absolute;
    bottom: 15px;
}

#img-cont-content div:nth-child(2) h1{
    margin: 0;
    font-size: 62px;
    line-height: 0.95;
}




.skill-indicator{
    width: fit-content;
    height: fit-content;
    margin: 0 auto 15px auto;
}

.indicator-bg{
    border-radius: 50%;
}


.ant-progress{
    display: inline-block;
    position: absolute;
}

.ant-progress-inner{
    margin: 0;
}


#contact-input{
}