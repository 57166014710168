@import url('https://fonts.googleapis.com/css?family=Dosis');

:root {
  /* generic */
  --gutterSm: 0.4rem;
  --gutterMd: 0.8rem;
  --gutterLg: 1.6rem;
  --gutterXl: 2.4rem;
  --gutterXx: 7.2rem;
  --colorPrimary400: #7e57c2;
  --colorPrimary600: #5e35b1;
  --colorPrimary800: #4527a0;
  --fontFamily: "Dosis", sans-serif;
  --fontSizeSm: 1.2rem;
  --fontSizeMd: 1.6rem;
  --fontSizeLg: 2.1rem;
  --fontSizeXl: 2.8rem;
  --fontSizeXx: 3.6rem;
  --lineHeightSm: 1.1;
  --lineHeightMd: 1.8;
  --transitionDuration: 300ms;
  --transitionTF: cubic-bezier(0.645, 0.045, 0.355, 1);

  /* floated labels */
  --inputPaddingV: var(--gutterSm);
  --inputPaddingH: var(--gutterMd);
  --inputFontSize: var(--fontSizeSm);
  --inputLineHeight: var(--lineHeightMd);
  --labelScaleFactor: 0.8;
  --labelDefaultPosY: 50%;
  --labelTransformedPosY: calc(
          (var(--labelDefaultPosY)) -
          (var(--inputPaddingV) * var(--labelScaleFactor)) -
          (var(--inputFontSize) * var(--inputLineHeight))
  );
  --inputTransitionDuration: var(--transitionDuration);
  --inputTransitionTF: var(--transitionTF);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



.Wrapper {
  flex: 0 0 80%;
  color: #455A64;
  font-family: var(--fontFamily);
  font-size: var(--fontSizeMd);
  line-height: var(--lineHeightMd);
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.Title {
  margin: 0 0 var(--gutterXx) 0;
  padding: 0;
  color: #fff;
  font-size: var(--fontSizeXx);
  font-weight: 400;
  line-height: var(--lineHeightSm);
  text-align: center;
  text-shadow: -0.1rem 0.1rem 0.2rem var(--colorPrimary800);
}

.Input {
  position: relative;
  margin-top: 45px;
}

.Input-text {
  display: block;
  margin: 0;
  padding: var(--inputPaddingV) var(--inputPaddingH);
  color: inherit;
  width: 100%;
  font-family: inherit;
  font-size: var(--inputFontSize);
  font-weight: inherit;
  line-height: var(--inputLineHeight);
  border: none;
  border-radius: 0.4rem;
  transition: box-shadow var(--transitionDuration);
}

.Input-text::placeholder {
  color: #B0BEC5;
}

.Input-text:focus {
  outline: none;
  box-shadow: 0.2rem 0.2rem 0.3rem #000000c7;
}

.Input-label {
  display: block;
  position: absolute;
  bottom: 35%;
  left: 1rem;
  color: #fff;
  font-family: inherit;
  font-size: var(--inputFontSize);
  font-weight: inherit;
  line-height: var(--inputLineHeight);
  opacity: 0;
  transform:
          translate3d(0, var(--labelDefaultPosY), 0)
          scale(1);
  transform-origin: 0 0;
  transition:
          opacity var(--inputTransitionDuration) var(--inputTransitionTF),
          transform var(--inputTransitionDuration) var(--inputTransitionTF),
          visibility 0ms var(--inputTransitionDuration) var(--inputTransitionTF),
          z-index 0ms var(--inputTransitionDuration) var(--inputTransitionTF);
}



.Input-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform:
          translate3d( calc(-1 * var(--inputPaddingV)) , var(--labelTransformedPosY), 0)
          scale(var(--labelScaleFactor))
          translateZ(0)
          perspective(1px);
  transition:
          transform var(--inputTransitionDuration),
          visibility 0ms,
          z-index 0ms;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.Textarea .Input-label{
  transform: translate3d(calc(-1 * var(--inputPaddingV)), -225px, 0)
              scale(var(--labelScaleFactor));
}