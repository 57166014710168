@font-face {
    font-family: "Jaapokki enchance";
    font-style: normal;
    font-weight: 400;
    src: url("../../fonts/Jaapokkienchance-Regular.otf");
}

@font-face {
    font-family: "Jaapokki subtract";
    font-style: normal;
    font-weight: 400;
    src: url("../../fonts/Jaapokkisubtract-Regular.otf");
}

/*@font-face {
    font-family: "Jaapokki";
    font-style: normal;
    font-weight: 400;
    src: url("../../fonts/Jaapokki-Regular.otf");
}*/


#navbar {
    background-color: white;
    text-align: center;
    font-family: "Jaapokki subtract", sans-serif;
    font-size: 60px;
    padding-top: 19px;
    padding-bottom: 7px;
    border-radius: 0 0 0 50px;
    position: absolute;
    z-index: 10;
    line-height: 1;
    width: 100%;
    box-shadow: -2px 2px 5px 3px #2347ab14;
}